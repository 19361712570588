import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { ListAlt, AddCircle } from '@mui/icons-material';

function Footer({ onNavigate }) {
  return (
    <BottomNavigation
      onChange={(event, newValue) => {
        onNavigate(newValue);
      }}
      showLabels
    >
      <BottomNavigationAction label="Ads" value="adList" icon={<ListAlt />} />
      <BottomNavigationAction label="Create Ad" value="createAd" icon={<AddCircle />} />
    </BottomNavigation>
  );
}

export default Footer;
