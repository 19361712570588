import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, Grid, Toolbar } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchAds } from '../api';

function AdList() {

  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadAds();
  }, []);

  const loadAds = async () => {
    try {
      const newAds = await fetchAds(page, 10);
      setAds(prevAds => [...prevAds, ...newAds]);
      setHasMore(newAds.length > 0);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Failed to load ads:', error);
    }
  };

  return (
    <InfiniteScroll
    dataLength={ads.length}
    next={loadAds}
    hasMore={hasMore}
    loader={<div style={{ display: 'flex', justifyContent: 'center', padding: '20px', minHeight: '80px' }}>
            <CircularProgress size={50} />
          </div>}
    endMessage={
      <Typography align="center">
        <b>You have seen it all</b>
      </Typography>
    }
  >
    <Toolbar />
    <Grid container spacing={2}>
      {ads.map(ad => (
        <Grid item xs={12} sm={6} key={ad.id}>
          <Card>
            <CardContent>
              <Typography variant="h6">{ad.description}</Typography>
              <Typography variant="body2">Cost per click: ${ad.costPerClick.toFixed(2)}</Typography>
              <Button
                variant="contained"
                color="primary"
                href={ad.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </InfiniteScroll>
  );
}

export default AdList;
