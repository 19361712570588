import React, { useEffect, useState } from 'react';
import { WebAppProvider, useInitData, useThemeParams, WebAppUser, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { CssBaseline, AppBar, Toolbar, Typography, Button, Box, Container } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import AdList from './components/AdList';
import CreateAd from './components/CreateAd';
import UserProfile from './components/UserProfile';
import { setInitDataHeader, fetchBalance } from './api';
import './App.css';

function App() {
  const [initDataUnsafe, initData] = useInitData();

  const themeParams = useThemeParams();
  const user = initDataUnsafe?.user || {};

  const [balance, setBalance] = useState(0);
  const [view, setView] = useState('adList');

    // Функция для обновления баланса
    const updateBalance = (fetchBalance) => {
      // Здесь будет логика для получения нового баланса с бэкенда
      // Для примера, мы установим фиксированный баланс
      setBalance(100);
//      fetchBalance().then(setBalance).catch(console.error);
    };
  
    // useEffect для инициации
    useEffect(() => {
      if (initData) {
        setInitDataHeader(initData);
        updateBalance(fetchBalance);
      }
    }, []);

//  useEffect(() => {
//    setBalance(100);

    // Здесь мы можем сделать запрос на бэкенд для получения баланса пользователя
    // Пример: fetch('/api/balance').then(response => response.json()).then(data => setBalance(data.balance));
//  }, []);

  return (
    <WebAppProvider>
      <CssBaseline />
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <header>
          <Header user={user} themeParams={themeParams} onNavigate={setView} balance={balance} />
        </header>
        <Container component="main" sx={{ flexGrow: 1, padding: '2rem 0', flex: 1 }}>
          {view === 'adList' && <AdList updateBalance={updateBalance} />}
          {view === 'createAd' && <CreateAd balance={balance} updateBalance={updateBalance} />}
          {view === 'userProfile' && <UserProfile updateBalance={updateBalance} />}
        </Container>
        <footer>
          <Footer onNavigate={setView} />
        </footer>
      </Box>
    </WebAppProvider>
  );
}

export default App;
