import axios from 'axios';

// Базовый URL API и создание экземпляра axios
const API_BASE_URL = 'https://linkomat.borisklimenko.ru';
//const API_BASE_URL = 'http://localhost:3000';
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Функция для установки initData в заголовки
export const setInitDataHeader = (initData) => {
  apiClient.defaults.headers.common['X-Init-Data'] = initData;
};

// Функция для получения списка реклам
export const fetchAds = async (page = 1) => {
  try {
//    const response = await apiClient.get('/api/ads', {
  await new Promise(resolve => setTimeout(resolve, 500));
    
  const response = await apiClient.get('/ads.json', {
      params: { page }
    });
    return response.data.ads;
  } catch (error) {
    console.error('Error fetching ads:', error);
    throw error;
  }
};

// Функция для получения баланса
export const fetchBalance = async () => {
  try {
//    const response = await apiClient.get('/api/balance');
    const response = await apiClient.get('balance.json');
    return response.data.balance;
  } catch (error) {
    console.error('Error fetching balance:', error);
    throw error;
  }
};

// Функция для создания рекламы
export const createAd = async (adDetails) => {
  try {
//    const response = await apiClient.post('/api/createAd', adDetails);
    const response = await apiClient.get('balance.json');
    return response.data;
  } catch (error) {
    console.error('Error creating ad:', error);
    throw error;
  }
};