import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Toolbar } from '@mui/material';
import Decimal from 'decimal.js';
import { createAd } from '../api';

function CreateAd({ balance, updateBalance }) {
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [costPerClick, setCostPerClick] = useState('');
  const [clicks, setClicks] = useState('');

    // Функция для безопасного создания Decimal
    const safeDecimal = (value) => {
      // Преобразуем значение в число, если оно корректное, иначе используем 0
      const num = Number(value);
      return isNaN(num) ? new Decimal(0) : new Decimal(num);
    };
  
   // Вычисление итоговой стоимости
   const totalCost = safeDecimal(costPerClick).mul(safeDecimal(clicks)).toDP(8);

   // Проверка, достаточно ли средств на балансе
   const isBalanceSufficient = totalCost.lte(balance);

  // Обработка создания рекламы
  const handleCreateAd = async () => {
    if (isBalanceSufficient) {
      try {
        const adDetails = {
          description: description,
          url: link,
          costPerClick: costPerClick,
          numberOfClicks: clicks
        };
        const response = await createAd(adDetails);
        alert('Success')
        updateBalance();  // Обновить баланс после создания рекламы
      } catch (error) {
        console.error('Failed to create ad:', error);
      }
    } else {
      console.log('Not enough balance');
    }
  };

  // Функция для обработки изменения в поле "Number of clicks"
  const handleClicksChange = (e) => {
    const value = e.target.value;
    // Проверка: если значение целое и не отрицательное
    if (/^[1-9]\d*$/.test(value)) {
      setClicks(value);
    }
  };

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <Toolbar />
      <Typography variant="h6">Create Ad</Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Cost per click"
        value={costPerClick}
        onChange={(e) => setCostPerClick(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        label="Number of clicks"
        value={clicks}
        inputProps={{ step: "1", min: "0", pattern: "[0-9]*" }} // Ограничение ввода только целыми числами
        onChange={handleClicksChange}
      />
      {/* Отображение итоговой стоимости */}
      <Typography variant="h6" margin="normal">
        Total cost: {totalCost.toString()}
      </Typography>

      {/* Сообщение об ошибке при недостаточном балансе */}
      {!isBalanceSufficient && (
        <Typography variant="body1" color="error">
          Insufficient balance for this operation.
        </Typography>
      )}

      {/* Кнопка отправки формы */}
      <Button
        variant="contained"
        color="primary"
        disabled={!isBalanceSufficient}
        fullWidth
        sx={{ mt: 3, mb: 2 }}
        onClick={handleCreateAd}
      >
        Create Ad
      </Button>
    </Box>
  );
}

export default CreateAd;
