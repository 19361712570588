import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { WebAppProvider, useInitData, useThemeParams } from '@vkruglikov/react-telegram-web-app';

function Header({ user, balance, themeParams, onNavigate }) {

  return (
    <AppBar position="fixed" style={{ backgroundColor: themeParams.bg_color }}>
    <Toolbar>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Linkomat
      </Typography>
      <Button color="inherit" onClick={() => onNavigate('userProfile')}>
        {user.username ? `@${user.username}` : '`#${user.id}`'}
      </Button>
      <Typography variant="body1">
        Balance: {balance ? `${balance.toString()}` : ''}
      </Typography>
    </Toolbar>
  </AppBar>
  );
}

export default Header;
