import React from 'react';
import { Typography } from '@mui/material';
import { TonConnectUIProvider, TonConnectButton } from '@tonconnect/ui-react';

function UserProfile() {
  return (
    <div className="user-profile">
      <Typography variant="h6">User Profile</Typography>
      <TonConnectUIProvider manifestUrl="https://linkomat.borisklimenko.ru/tonconnect-manifest.json">
         <TonConnectButton />
     </TonConnectUIProvider>
    </div>
  );
}

export default UserProfile;
